













import { Component, Vue } from 'vue-property-decorator'

@Component
export default class PageNotFound extends Vue {
  private message: string = this.$t('common.message.not_found_404') as string

  created() {
    if (this.$route.params.msgCode) {
      this.message = this.$t(
        `common.message.${this.$route.params.msgCode}`
      ) as string
    }
  }
}
